import React from "react";
import { Redirect, RouteComponentProps, useLocation } from "@reach/router";
import { useUserContext } from "Contexts/User";
// import Loader from "react-loader-spinner";

interface ProtectedProps extends RouteComponentProps {}

export const Protected: React.FC<ProtectedProps> = ({ children }) => {
  /*if (loading) {
    return <Loader type="Grid" />;
  } else */

  const location = useLocation();

  const {
    state: { isAuthenticated },
  } = useUserContext();

  if (isAuthenticated) {
    return <>{children}</>;
  } else {
    return (
      <Redirect to="/login" noThrow state={{ returnTo: location.pathname }} />
    );
  }
};
