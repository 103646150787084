import { Router } from "@reach/router";
import { Protected } from "Routes/Protected/Protected";
import { Songs } from "Routes/Songs";
import { CreateSong } from "Routes/Protected/CreateSong";
import { TermsOfService } from "Routes/TermsOfService";
import { AboutUs } from "Routes/AboutUs";
import { Login } from "Routes/Login";
import { Navbar } from "Components/Navbar/Navbar";
import { UserStateProvider } from "Contexts/User";
import { Profile } from "Routes/Protected/Profile";
import { MySongs } from "Routes/Protected/MySongs";
import styles from "App.module.css";
import { MainRoute } from "Routes/MainRoute";
import { SongStateProvider } from "Contexts/Songs";
import { Box } from "Components/Box/Box";

function App() {
  return (
    <>
      <UserStateProvider>
        <SongStateProvider>
          <div className={styles.container}>
            <Navbar />
            <div className={styles.main}>
              <main>
                <Box>
                  <Router>
                    <MainRoute path="/">
                      <Songs default />
                      <TermsOfService path="/ToS" />
                      <AboutUs path="/about-us" />
                      <Login path="/login" />
                      <Protected path="/user">
                        <CreateSong path="/create-song" />
                        <Profile path="/profile" />
                        <MySongs path="/songs" />
                      </Protected>
                    </MainRoute>
                  </Router>
                </Box>
              </main>
            </div>
            <div className={styles.footer}>
              <footer>Mehmet Burak Dönmez</footer>
            </div>
          </div>
        </SongStateProvider>
      </UserStateProvider>
    </>
  );
}

export default App;
