import React, { useState } from "react";
import { navigate, RouteComponentProps } from "@reach/router";
import formStyle from "Assets/css/form.module.css";
import { useSongContext, SongActionType } from "../../Contexts/Songs";
import { useUserContext } from "../../Contexts/User";
import { v4 as uuidv4 } from "uuid";

interface CreateSongProps extends RouteComponentProps {}

export const CreateSong: React.FC<CreateSongProps> = () => {
  const [songName, setSongName] = useState<string>("");
  const [artist, setArtist] = useState<string>("");
  const [, setFile] = useState<File | undefined>();
  const [fileName, setFileName] = useState<string>();
  const { dispatch } = useSongContext();
  const {
    state: { user },
  } = useUserContext();

  function onFormSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    dispatch({
      type: SongActionType.ADD,
      song: {
        name: songName,
        artist: artist,
        duration: "4:20",
        rating: 4.2,
        uploadedBy: user!,
        id: uuidv4(),
      },
    });
    navigate("/");
  }

  const onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { validity, files },
    } = event;
    if (validity.valid && files) {
      if (files.length === 1) {
        setFile(files[0]);
        setFileName(files[0].name);
      } else {
        setFile(undefined);
        setFileName(undefined);
      }
    }
  };

  console.log("Creating Songs");
  return (
    <>
      <div className={formStyle.fromContainer}>
        <div className={formStyle.main}>
          <p className={formStyle.sign}>Create Song</p>
          <form className={formStyle.form1} onSubmit={onFormSubmit}>
            <input
              className={formStyle.input}
              type="text"
              placeholder="Song Name"
              onChange={(e) => setSongName(e.target.value)}
            />
            <input
              className={formStyle.input}
              type="text"
              placeholder="Artist"
              onChange={(e) => setArtist(e.target.value)}
            />
            <label
              className={`${formStyle.input} ${formStyle.fileLabel}`}
              htmlFor="createSongFileSelector"
            >
              {fileName ? fileName : "Select File"}
            </label>
            <input
              id="createSongFileSelector"
              type="file"
              placeholder="File"
              onChange={onFileChange}
              accept=".mp3"
              style={{ display: "none" }}
            />
            <button type="submit" className={formStyle.submit}>
              Create
            </button>
          </form>
        </div>
      </div>
    </>
  );
};
