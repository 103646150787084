import {
  createContext,
  Dispatch,
  PropsWithChildren,
  useContext,
  useReducer,
} from "react";

export interface User {
  username: string;
  email: string;
}

export interface UserStateContext {
  isAuthenticated: boolean;
  user?: User;
}

export interface UserStore {
  state: UserStateContext;
  dispatch: Dispatch<Action>;
}

export enum UserActionType {
  LOG_IN = "Log in",
  LOG_OUT = "Log out",
}

export type Action =
  | { type: UserActionType.LOG_IN; user: User }
  | { type: UserActionType.LOG_OUT };

export const reducer = (
  state: UserStateContext,
  action: Action
): UserStateContext => {
  switch (action.type) {
    case UserActionType.LOG_IN:
      const newState = { isAuthenticated: true, user: action.user };
      window.localStorage.setItem("UserConext", JSON.stringify(newState));
      return newState;
    case UserActionType.LOG_OUT:
      const newState2 = { isAuthenticated: false };
      window.localStorage.setItem("UserConext", JSON.stringify(newState2));
      return { isAuthenticated: false };
    default:
      throw new Error("Not among actions");
  }
};

const defaultState: UserStateContext = JSON.parse(
  window.localStorage.getItem("UserConext") ??
    JSON.stringify({ isAuthenticated: false })
);
const UserContext = createContext<UserStore>({
  state: defaultState,
  dispatch: () => null,
});

export const useUserContext = () => useContext(UserContext);

export const UserStateProvider = ({ children }: PropsWithChildren<{}>) => {
  const [state, dispatch] = useReducer(reducer, defaultState);
  return (
    <UserContext.Provider value={{ state, dispatch }} children={children} />
  );
};
