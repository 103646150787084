import { RouteComponentProps } from "@reach/router";
import React from "react";
import { useSongContext } from "Contexts/Songs";
import { SongTable } from "../Components/SongTable/SongTable";

interface SongsProps extends RouteComponentProps {}

export const Songs: React.FC<SongsProps> = () => {
  //This is a placeholder data, will be replaced once the backend is implemented
  const {
    state: { songs },
  } = useSongContext();

  return (
    <>
      <SongTable songs={songs} />
    </>
  );
};
