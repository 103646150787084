import {
  createContext,
  Dispatch,
  PropsWithChildren,
  useContext,
  useReducer,
} from "react";
import { User } from "./User";

export interface Song {
  name: string;
  artist: string;
  uploadedBy: User;
  duration: string;
  rating: number;
  id: string;
}

const InitialSongs: Song[] = [
  {
    name: "Nothing Else Metters",
    artist: "Metallica",
    uploadedBy: {
      email: "donmezmehmetburak@gmail.com",
      username: "Burak",
    },
    duration: "6:30",
    rating: 9.5,
    id: "2052481f-25e6-43ed-875e-c93ab7efbe75",
  },
  {
    name: "We Are The Champions",
    artist: "Queen",
    uploadedBy: {
      email: "donmezmehmetburak@gmail.com",
      username: "Other Burak",
    },
    duration: "3:00",
    rating: 9.9,
    id: "196e37b3-62eb-42be-a9f8-4bdd3bace110",
  },
];

export interface SongStateContext {
  songs: Song[];
}

export type Action =
  | { type: SongActionType.ADD; song: Song }
  | { type: SongActionType.REMOVE; id: string };

export interface SongStore {
  state: SongStateContext;
  dispatch: Dispatch<Action>;
}

export enum SongActionType {
  ADD = "Add",
  REMOVE = "Remove",
}

export const reducer = (
  state: SongStateContext,
  action: Action
): SongStateContext => {
  switch (action.type) {
    case SongActionType.ADD:
      state.songs.push(action.song);
      window.localStorage.setItem("SongContext", JSON.stringify(state));
      return state;
    case SongActionType.REMOVE:
      const index = state.songs.findIndex((v) => v.id === action.id);
      if (index > -1) {
        state.songs.splice(index, 1);
      }
      window.localStorage.setItem("SongContext", JSON.stringify(state));
      return state;
    default:
      throw new Error("Not among actions");
  }
};

const defaultState: SongStateContext = JSON.parse(
  window.localStorage.getItem("SongContext") ??
    JSON.stringify({ songs: InitialSongs })
);

const SongContext = createContext<SongStore>({
  state: defaultState,
  dispatch: () => null,
});

export const useSongContext = () => useContext(SongContext);

export const SongStateProvider = ({ children }: PropsWithChildren<{}>) => {
  const [state, dispatch] = useReducer(reducer, defaultState);
  return (
    <SongContext.Provider value={{ state, dispatch }} children={children} />
  );
};
