import React from "react";
import { RouteComponentProps } from "@reach/router";
import { useSongContext } from "../../Contexts/Songs";
import { SongTable } from "../../Components/SongTable/SongTable";
import { useUserContext } from "../../Contexts/User";

interface MySongsProps extends RouteComponentProps {}

export const MySongs: React.FC<MySongsProps> = () => {
  const {
    state: { songs },
  } = useSongContext();

  const {
    state: { user },
  } = useUserContext();
  return (
    <>
      <SongTable
        songs={songs.filter((v) => v.uploadedBy.username === user?.username)}
      />
    </>
  );
};
