import React from "react";
import { Song } from "../../Contexts/Songs";
import styles from "./songtable.module.css";

interface SongTableProps extends React.TableHTMLAttributes<HTMLTableElement> {
  songs: Song[];
}

export const SongTable: React.FC<SongTableProps> = ({ songs, ...args }) => {
  return (
    <table className={styles.songsTable} {...args}>
      <thead>
        <tr>
          <th>Song</th>
          <th>Artist</th>
          <th>Uploaded By</th>
          <th className={styles.narrowColumn}>Duration</th>
          <th className={styles.narrowColumn}>Rating</th>
          <th className={styles.narrowColumn}>Play</th>
        </tr>
      </thead>
      <tbody>
        {songs.map((song) => (
          <tr key={song.id}>
            <td>{song.name}</td>
            <td>{song.artist}</td>
            <td>{song.uploadedBy.username}</td>
            <td className={styles.narrowColumn}>{song.duration}</td>
            <td className={styles.narrowColumn}>{song.rating}/10</td>
            {/* Playing logic will be added after webgl implementation */}
            <td className={styles.narrowColumn}>Play</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
