import React, { useState } from "react";
import { Redirect, RouteComponentProps, WindowLocation } from "@reach/router";
import formStyle from "Assets/css/form.module.css";
import { UserActionType, useUserContext } from "Contexts/User";

interface LoginProps extends RouteComponentProps {
  location?: {
    state: {
      returnTo: string;
    };
  } & WindowLocation;
}

export const Login: React.FC<LoginProps> = ({ location }) => {
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const {
    state: { isAuthenticated },
    dispatch,
  } = useUserContext();

  function onFormSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    dispatch({
      type: UserActionType.LOG_IN,
      user: {
        email: "my_email@gmail.com",
        username,
      },
    });
  }

  return (
    <>
      {isAuthenticated ? (
        <Redirect to={location?.state.returnTo ?? "/"} noThrow />
      ) : (
        <div className={formStyle.fromContainer}>
          <div className={formStyle.main}>
            <p className={formStyle.sign}>Sign in</p>
            <span className={formStyle.devNotice}>
              (Any username/password is valid for development)
            </span>
            <form className={formStyle.form1} onSubmit={onFormSubmit}>
              <input
                className={formStyle.input}
                type="text"
                placeholder="Username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
              <input
                className={formStyle.input}
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <button type="submit" className={formStyle.submit}>
                Sign in
              </button>
            </form>
          </div>
        </div>
      )}
    </>
  );
};
