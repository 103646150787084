import { Link, LinkGetProps } from "@reach/router";
import logo from "Assets/electric-guitar.png";
import styles from "./navbar.module.css";
import { UserActionType, useUserContext } from "Contexts/User";

interface NavbarProps {}

export const Navbar: React.FC<NavbarProps> = () => {
  const isActive = ({ isCurrent }: LinkGetProps) => {
    return isCurrent
      ? { className: `${styles.active_navlink} ${styles.navlink}` }
      : {};
  };

  const {
    state: { isAuthenticated, user },
    dispatch,
  } = useUserContext();

  return (
    <nav>
      <div className={styles.navbarContainer}>
        <div className={styles.navbar}>
          <Link to="/">
            <div className={styles.navbar_left}>
              <img src={logo} alt="fretsoncloud logo" />
            </div>
          </Link>
          <div className={styles.navbar_content}>
            <Link className={styles.navlink} to="/" getProps={isActive}>
              Home
            </Link>
            <Link
              className={styles.navlink}
              to="/user/create-song"
              getProps={isActive}
            >
              Create Song
            </Link>
            <Link className={styles.navlink} to="/ToS" getProps={isActive}>
              Terms Of Service
            </Link>
            <Link className={styles.navlink} to="/about-us" getProps={isActive}>
              About Us
            </Link>
          </div>
          <div className={styles.navbar_right}>
            {isAuthenticated ? (
              <>
                <Link
                  className={styles.navlink}
                  to="/user/profile"
                  getProps={isActive}
                >
                  User: {user?.username}
                </Link>
                <Link
                  className={styles.navlink}
                  to="/user/songs"
                  getProps={isActive}
                >
                  My Songs
                </Link>
                <div
                  className={styles.navlink}
                  onClick={() => dispatch({ type: UserActionType.LOG_OUT })}
                >
                  Logout
                </div>
              </>
            ) : (
              <Link className={styles.navlink} to="/login" getProps={isActive}>
                Login/Register
              </Link>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
};
