import React from "react";
import styles from "./box.module.css";

interface BoxProps {
  center?: boolean;
}

export const Box: React.FC<BoxProps> = ({ children, center = false }) => {
  return <div className={styles.box}>{children}</div>;
};
